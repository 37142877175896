<template>
  <b-form-group
    :class="[
      `${feedbackId}__wrapper`,
      errors[0] ? 'group-is-invalid' : 'group-is-valid',
      groupClasses,
      required ? 'required' : '',
    ]"
  >
    <template
      v-if="label"
      #label
      :class="labelClasses"
      :for="feedbackId ? feedbackId : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <span class="label">{{ label }}</span>
      </div>
    </template>
    <b-input-group :prepend="prepend" :append="append">
      <b-form-input
        :id="feedbackId"
        ref="feedbackId"
        v-validate="
          (rules ? rules + '|' : '') +
          (required ? 'required|' : '') +
          `max:${maxRule}${type && type === 'number' ? '|numeric|integer' : ''}`
        "
        :placeholder="placeholder ? placeholder : label"
        :aria-describedby="feedbackId"
        :autocomplete="autocomplete ? autocomplete : 'on'"
        :name="feedbackId"
        v-bind="$attrs"
        :data-vv-as="label || placeholder ? label || placeholder : feedbackId"
        :type="type"
        :data-test="dataTest"
        :state="!isEmpty ? !(errors && errors.first(feedbackId)) : null"
        :required="required"
        v-on="$listeners"
        @blur="checkInput || (isEmpty = true)"
      />
      <!-- TODO : fix state-->

      <b-input-group-append v-if="type && type == 'password'">
        <button
          class="
            show_password
            btn-transparent
            border-0
            d-flex
            align-items-center
          "
          type="button"
          @click="togglePassword"
        >
          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" />
        </button>
      </b-input-group-append>
    </b-input-group>
    <transition name="slide">
      <ul
        v-if="errors && errors.first(feedbackId)"
        class="text-danger p-0 list-style-none mb-0"
      >
        {{
          errors.first(feedbackId)
        }}
      </ul>
    </transition>
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
// TODO : create dynamic import for rules

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "on",
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    append: {
      type: String,
      required: false,
      default: null,
    },
    prepend: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    labelAppendIcon: {
      type: Object,
      required: false,
      default: () => {},
    },
    groupClasses: {
      type: String,
      required: false,
      default: "",
    },
    maxRule: {
      type: Number,
      required: false,
      default: 150,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataTest: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isEmpty: true,
      isPasswordShown: false,
      // errors: [],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.isPasswordShown ? "EyeIcon" : "EyeOffIcon";
    },
    feedbackId() {
      if (!this.label) return "input-feedback";
      return `input${this.label.replace(/ /g, "-")}-feedback`;
    },
    labelClasses() {
      return [
        !this.isEmpty || this.$attrs.value ? "active" : "",
        this.errors ? "color-danger" : "",
      ];
    },
  },
  methods: {
    formGroupClassesHandler(id) {
      //
    },
    checkInput() {
      if (this.$refs[this.feedbackId].value.length === 0) {
        this.isEmpty = true;
      } else {
        this.isEmpty = false;
      }
    },
    togglePassword() {
      if (document.querySelector(`#${this.feedbackId}`).type === "password") {
        document.querySelector(`#${this.feedbackId}`).type = "text";
        this.isPasswordShown = true;
      } else {
        document.querySelector(`#${this.feedbackId}`).type = "password";
        this.isPasswordShown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show_password {
  border-radius: 0 4px 4px 0;

  svg {
    margin: 0 10px;
  }

  [dir="rtl"] & {
    border-radius: 4px 0 0 4px;
  }
}

input[type="password"] {
  [dir="rtl"] & {
    border-radius: 0 4px 4px 0 !important;
  }
}
</style>
