<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group :class="{ required: required }">
      <template v-slot:label>
        <section
          class="d-flex justify-content-between align-items-center w-100"
        >
          <label :for="generateID" v-html="label" />
          <button
            class="btn bg-transparent border-0 p-0 w-auto rounded-0 text-danger"
            :disabled="!data || !data.length"
            :class="{
              'btn-disabled ': !data || !data.length,
            }"
            @click="clearState"
            @keydown="clearState"
          >
            {{ $t("clear") || "Clear" }}
          </button>
        </section>
      </template>

      <div
        class="border rounded font-13 cursor-pointer text-center"
        :class="generateID"
        @click="openModalHandler"
      >
        <transition name="slide">
          <div
            v-if="files && files.length"
            class="d-flex align-items-center justify-content-center flex-wrap"
          >
            <span
              v-for="(file, index) in files"
              :key="index"
              class="d-flex flex-column m-2 text-center overflow-hidden"
            >
              <img
                v-if="isImg(file, index)"
                :src="file"
                alt=""
                width="100"
                height="100"
                class="p-2 rounded object-fit-contain"
              />
              <file-icon
                v-else
                size="3x"
                width="100"
                height="100"
                class="cursor-pointer text-primary"
              ></file-icon>
              {{ data[index]["name"] || "Document" }}
            </span>
          </div>
          <div v-else class="py-2 text-center">
            <strong>
              {{ $t("select_files") || "Select Files" }}
            </strong>
            {{ $t("or_drag_and_drop_here") || "Or Drag And Drop Here" }}
          </div>
        </transition>
      </div>

      <b-form-file
        v-if="!readonly"
        :id="generateID"
        :multiple="multiple"
        :state="Boolean(data)"
        :placeholder="$t('select_files') || 'Select Files'"
        :drop-placeholder="$t('drop_files_here')"
        class="d-none"
        :name="generateID"
        v-bind="$attrs"
        :data-vv-as="label"
        @change="handleFormFile"
      />

      <transition name="slide">
        <ul
          v-if="validationContext.errors && validationContext.errors.length"
          class="text-danger p-0 list-style-none mb-0"
        >
          <li v-for="(error, key) in validationContext.errors" :key="key">
            {{ error }}
          </li>
        </ul>
      </transition>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BForm, BFormGroup, BFormFile } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { DownloadIcon, FileIcon, EyeIcon, XIcon } from "vue-feather-icons";

export default {
  components: {
    BFormGroup,
    BFormFile,
    BForm,
    ValidationProvider,
    DownloadIcon,
    FileIcon,
    EyeIcon,
    XIcon,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    validationRules: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [Array, String],
      required: false,
      default: null,
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  computed: {
    files() {
      if (!this.data && this.data.length) return;

      return this.generateFiles(this.data);
    },
    generateID() {
      return this.id
        ? this.id
        : "app-file-preview-" +
            Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
    },
  },
  mounted() {},
  created() {
    if (this.value) this.data = this.value;
  },
  methods: {
    isImg(file, i) {
      return (
        this.data[i] &&
        (this.data[i]["name"].slice(-3).toLowerCase() === "jpg" ||
          this.data[i]["name"].slice(-3).toLowerCase() === "jpeg" ||
          this.data[i]["name"].slice(-3).toLowerCase() === "png" ||
          this.data[i]["name"].substring(0, 5).toLowerCase() === "data:")
      );
    },

    generateFiles(data) {
      console.log(data);
      if (!data) return [];
      if (typeof data === "string") return [data];
      return data.map((el) =>
        typeof el === "string" ? el : URL.createObjectURL(el)
      );
    },
    clearState() {
      this.data = [];
      this.$emit("changeFiles", this.data);
    },
    openModalHandler() {
      if (this.readonly) return;
      document.querySelector(`#${this.generateID}`).click();
    },
    handleFormFile(e) {
      this.data = [...e.target.files];
      this.$emit("changeFiles", this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
