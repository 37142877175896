// check if current user can do something
export default function userCan(action, user = window.localStorage.getItem('userData') || null) {
    const userData = typeof user === 'object' ? user : JSON.parse(user);

    if (!userData) {
        if (this && this.$router) this.$router.push('/login')
        return false
    }

    const isAdmin = userData.permissions_actions?.length === 0

    if (isAdmin) return true

    const userRoles = userData.permissions_actions

    if (userRoles && userRoles.includes(action)) return true

    return false
}
