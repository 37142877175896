
import axios from '@axios'
import CRUD from '@/store/mixins/crud'

const crud = new CRUD('award')

export default {
    namespaced: true,
    state: {
        ...crud.state,
        nonTechJudgesErrors: [],
        techJudgesErrors: [],
        kpisErrors: [],
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
        SET_NON_TECH_JUDGES_ERRORS(state, payload) {
            state.nonTechJudgesErrors = payload
        },
        SET_TECH_JUDGES_LOADING(state, payload) {
            state.techJudgesLoading = payload
        },
        SET_TECH_JUDGES_ERRORS(state, payload) {
            state.techJudgesErrors = payload
        },
        SET_KPIS_ERRORS(state, payload) {
            state.kpisErrors = payload
        }
    },
    actions: {
        ...crud.actions,
        assignKpis({ commit, dispatch, }, payload) {
            commit('SET_LOADING', { fetching: true })

            return new Promise((resolve, reject) => {
                axios.post(`/award/assign-kpis`, payload)
                    .then(response => {
                        dispatch('fetchData')
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_KPIS_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        if (error.response && error.response.data) commit('SET_KPIS_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
        assignNonTechJudges({ commit, dispatch, }, payload) {
            commit('SET_LOADING', { fetching: true })

            return new Promise((resolve, reject) => {
                axios.post(`/award/assign-judges`, payload)
                    .then(response => {
                        dispatch('fetchData')
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_NON_TECH_JUDGES_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        if (error.response && error.response.data) commit('SET_NON_TECH_JUDGES_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
        assignTechJudges({ commit, dispatch, }, payload) {
            commit('SET_LOADING', { fetching: true })

            return new Promise((resolve, reject) => {
                axios.post(`/award/assign-technical-judges`, payload)
                    .then(response => {
                        dispatch('fetchData')
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_TECH_JUDGES_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        if (error.response && error.response.data) commit('SET_TECH_JUDGES_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        }
    },
}
