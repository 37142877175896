<template>
  <!-- (isLoading ? ` (${$t('loading') || 'loading'}) ` : '') -->
  <b-form-group
    :for="id"
    :label="fieldLabel || label"
    :class="[
      `${feedbackId}__wrapper`,
      errors[0] ? 'group-is-invalid' : 'group-is-valid',
      formGroupClasses,
    ]"
    label-class="label"
  >
    <v-select
      :id="id"
      ref="select"
      v-validate="
        rules + (rules && required ? '|' : '') + (required ? 'required' : '')
      "
      :data-vv-as="fieldLabel || label || ''"
      :placeholder="placeholder"
      class="w-100"
      :name="feedbackId"
      :filterable="!searchStoreAction"
      :dir="isRTL ? 'rtl' : 'ltr'"
      :loading="isLoading"
      :options="allOptions"
      :label="'name_ar'"
      max-height="150px"
      :required="required"
      :multiple="multiple"
      :clearable="clearable"
      v-bind="$attrs"
      @search="onSearchHandler"
      v-on="$listeners"
    />
    <!-- :label="isRTL ? 'name_ar' : 'name'" -->

    <transition name="slide">
      <ul
        v-if="errors && errors.first(id)"
        class="text-danger p-0 list-style-none mb-0"
      >
        {{
          errors.first(id)
        }}
      </ul>
    </transition>
    <!-- <div class="spacer-5"></div> -->
  </b-form-group>
</template>

<script>
import vSelect from "vue-select";
import { BFormGroup } from "bootstrap-vue";
import i18n from "@/libs/i18n/index";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    fieldLabel: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "app-select",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    formGroupClasses: {
      type: String,
      default: "",
    },
    searchStoreAction: {
      type: String,
      default: null,
    },
    searchKey: {
      type: String,
      default: "name",
    },
    searchParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: i18n.t("select") || "Select",
    },
  },
  data() {
    return {
      timeout: null,
      dynamicOptions: null,
      storeModule: null,
    };
  },
  computed: {
    feedbackId() {
      if (!this.label) return "selectbox-feedback";
      return `selectbox${this.label.replace(/ /g, "-")}-feedback`;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    isLoading() {
      return this.storeModule
        ? this.$store.state[this.storeModule].isLoading.fetching
        : false;
    },
    allOptions() {
      if (!this.options || !this.options.length || this.isLoading) {
        return [
          // {
          //   label: this.$t("no_options") || "No options",
          //   name_ar: this.$t("no_options") || "No options",
          //   value: null,
          // },
        ];
      }

      return (this.dynamicOptions || this.options).map((item, index) => ({
        ...item,
        id: item ? item.id || item.uuid || index : null,
        name_ar:
          this.isRTL && item.name_ar
            ? item.name_ar || item.label || item.text || item.name
            : item.name || item.label || item.text || item.name_ar,
        label:
          this.isRTL && item.name_ar
            ? item.name_ar || item.label || item.text || item.name
            : item.name || item.label || item.text || item.name_ar,
        value: item.uuid || item.value,
      }));
    },
  },
  created() {
    if (this.searchStoreAction) {
      this.storeModule = this.searchStoreAction.split("/")[0];
    }
  },

  methods: {
    onSearchHandler(search) {
      if (!this.searchStoreAction) return;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$store.dispatch(
          this.searchStoreAction,
          search
            ? {
                [this.searchKey || "name"]: search,
                ...this.searchParams,
              }
            : { ...this.searchParams }
        );
      }, 500);
    },
  },
  watch: {
    options(newOptions) {
      if (newOptions && newOptions.length) {
        this.dynamicOptions = newOptions;
      } else {
        // this.$store.dispatch(this.searchStoreAction).then(() => {
        //   });
        this.dynamicOptions = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .vs__dropdown-menu {
//   max-height: 150px;
// }

// ::v-deep input {
//   padding: 3px;
// }

::v-deep .vs__selected-options > input {
  color: #bbbbc5;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
