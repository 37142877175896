export default {
    namespaced: true,
    state: {
        isSignedIn: false,
        renderSidebar: true,
        userData: null
    },
    mutations: {
        setRenderSidebar(state, payload) {
            state.renderSidebar = payload;
        },
        SET_USER_DATA(state, payload) {
            state.userData = payload;
        }
    },
    actions: {
        renderSidebarHandler({ commit, state }, payload = true) {
            commit('setRenderSidebar', payload);
        },
        setUserData({ commit, state }, payload) {
            commit('SET_USER_DATA', payload);
        }
    },
}
