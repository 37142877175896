<template>
  <div>
    <!-- Styled -->
    <b-form-file
      v-model="file"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    />

    <!-- Plain mode -->
    <!-- <b-form-file v-model="file2" class="mt-1" plain />
    <div class="mt-1">
      Selected file: <strong>{{ file2 ? file2.name : "" }}</strong>
    </div> -->
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue'

export default {
  components: {
    BFormFile,
  },
  data() {
    return {
      file: null,
      file2: null,
    }
  },
}
</script>
