
import axios from '@axios'
import CRUD from '@/store/mixins/crud'

const crud = new CRUD('rejection-reason')

export default {
    namespaced: true,
    state: {
        ...crud.state
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
    },
    actions: {
        ...crud.actions,
    },
}
