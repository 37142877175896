import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import VeeValidate from 'vee-validate/dist/vee-validate.js';
// import {
//   ValidationProvider,
//   ValidationObserver
// } from 'vee-validate';
// // veevalidate translations
// import ar from "vee-validate/dist/locale/ar";
// import en from "vee-validate/dist/locale/en";

import i18n from '@/libs/i18n'
// import VeeValidate from '@/libs/VeeValidate'
import router from './router'
import store from './store'
import App from './App.vue'
// import { isUserLoggedIn } from '@/auth/utils'
// import VeeValidate from 'vee-validate';

// const config = {
//   aria: true,
//   classNames: {},
//   classes: false,
//   delay: 0,
//   dictionary: null,
//   errorBagName: 'errors', // change if property conflicts
//   events: 'input|blur',
//   fieldsBagName: 'fields',
//   i18n: null, // the vue-i18n plugin instance
//   i18nRootKey: 'validations', // the nested key under which the validation messages will be located
//   inject: true,
//   locale: 'en',
//   validity: false,
//   useConstraintAttrs: true
// };

// Vue.use(VeeValidate, config);

// Vue.component('ValidationProvider', ValidationProvider);
// Vue.component('ValidationObserver', ValidationObserver);

// Vue.use(VeeValidate, {
//   i18n,
//   dictionary: {
//     ar: {
//       messages: ar.messages,
//       attributes: ar.attributes
//     },
//     en: {
//       messages: en.messages,
//       attributes: en.attributes
//     }
//   },
// });
// Vue.mixin({
//   methods: {
//     changeData: (prop, val) => this[prop] = val  }
// })

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// const userData = JSON.parse(localStorage.getItem('userData')) || null

new Vue({
  router,
  store,
  i18n,
  // userData,
  render: h => h(App),
}).$mount('#app')
