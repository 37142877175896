<template>
  <div>
    <b-form-group :label="label" class="mb-0">
      <b-form-radio-group
        button-variant="outline-primary"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormRadioGroup } from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
