import Vue from 'vue'
import VueRouter from 'vue-router'

import axios from '@axios'
import userCan from '@/mixins/UserCan'
import { isUserLoggedIn } from '@/auth/utils'

import pages from './routes/pages'
import misc from './routes/misc'
import i18n from '@/libs/i18n/index'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Home.vue'),
      meta: {
        pageTitle: i18n.t('Home') || 'Home',
        permission: ['dashboard-access'],
        breadcrumb: [
          {
            text: i18n.t('Home') || 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        public: true,
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    ...pages,
    ...misc,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!isUserLoggedIn()

  if (!isLoggedIn && to.name !== 'auth-login') {
    next('/login')
  }

  axios.post('users/is-authorized')

  return next()
})

router.beforeResolve((to, from, next) => {
  // if permission is string
  if (to.meta.permission && typeof to.meta.permission === 'string' && !userCan(to.meta.permission)) {
    return next({ name: 'error-403' })
  }

  // if permission is array of strings
  if (to.meta.permission && Array.isArray(to.meta.permission)) {
    const can = to.meta.permission.some(permission => userCan(permission))

    if (!can) return next({ name: 'error-403' })
  }

  next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
