import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// permissions module
import auth from './auth'
import actions from './actions'
import permissions from './permissions'
import roles from './roles'

// users module
import actors from './actors'
import users from './users'

// Awards module
import awards from './awards'


// articles module
import articles from './articles'
import gallery from './gallery'
import videos from './videos'


import articleCategory from './articles/article-category'
import articleTag from './articles/article-tag'
import comments from './articles/comments'
import pages from './pages'

// news module
import news from './news'
import news_category from './news/category'
import news_tag from './news/tag'
import newsComments from './news/comments'

// settings module
import sliders from './sliders'
import settings from './settings'
import accountSettings from './account-settings'
import countries from './settings/countries'
import governorates from './settings/governorates'

import application_individual from './applications/individual'
import application_team from './applications/team'

import contact_us from './contact-us'

import comity from './comity'
import comity_members from './comity/comity_members.js'
import judges from './comity/judges.js'
import rejection_reasons from './rejection-reasons/index'
import kpi from './kpi'
import home from './home'
import departments from './settings/departments'
import hospitalDirection from './settings/hospitalDirection'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    auth,
    actions,
    permissions,
    roles,
    actors,
    users,

    awards,

    accountSettings,

    countries,
    governorates,

    articles,
    gallery,
    videos,

    articleCategory,
    articleTag,
    comments,
    pages,
    news,
    sliders,
    settings,
    news_category,
    news_tag,
    newsComments,
    application_individual,
    application_team,

    contact_us,

    comity,
    comity_members,
    judges,
    rejection_reasons,

    kpi,

    home,
    departments,
    hospitalDirection
  },
  strict: process.env.DEV,
})
