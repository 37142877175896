<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group
      :class="[
        `${feedbackId}__wrapper`,
        errors[0] ? 'group-is-invalid' : 'group-is-valid',
      ]"
      :state="getValidationState(validationContext)"
    >
      <template
        v-if="label"
        #label
        :class="labelClasses || ''"
        :for="feedbackId ? feedbackId : ''"
      >
        <div class="d-flex align-items-center justify-content-between">
          <span class="label">{{ label }}</span>
        </div>
      </template>
      <b-form-tags
        class="p-0 border-0"
        v-bind="$attrs"
        :state="getValidationState(validationContext)"
        @input="tagsHandler"
        v-on="$listeners"
      >
        <!-- no-outer-focus -->
        <template v-slot="{ tags, disabled, addTag, removeTag }">
          <b-dropdown
            size="sm"
            variant="outline-transparent"
            block
            menu-class="w-100"
            class="border form-control d-flex align-items-center"
          >
            <template v-slot:button-content class="">
              <b-icon icon="tag-fill" />
              <span class="">{{ placeholder || "" }}</span>
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label-for="tag-search-input"
                :label="`Search ${label}`"
                label-cols-md="auto"
                class="mb-0 d-flex flex-column"
                label-class="no-validation"
                label-size="sm"
                :description="searchDesc"
                :disabled="disabled"
              >
                <b-form-input
                  id="tag-search-input"
                  v-model="search"
                  type="search"
                  size="sm"
                  autocomplete="off"
                  @input="emitSearch"
                />
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider />
            <b-dropdown-item
              v-for="option in availableOptions"
              :key="option.uuid"
              @click="onOptionClick({ option, addTag })"
            >
              {{ option.name }}
            </b-dropdown-item>
            <b-dropdown-text v-if="availableOptions.length === 0">
              There are no {{ label }} available to select
            </b-dropdown-text>
          </b-dropdown>

          <ul v-if="tags.length > 0" class="list-inline d-inline-block mt-1">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag
                :title="tag"
                :disabled="disabled"
                variant="primary"
                class="mb-1"
                @remove="removeTag(tag)"
              >
                <!-- TODO : fix this  -->
                {{ options.find((el) => el.uuid === tag).name }}
              </b-form-tag>
            </li>
          </ul>
        </template>
      </b-form-tags>
      <transition name="slide">
        <ul
          v-if="validationContext.errors && validationContext.errors.length"
          class="text-danger p-0 list-style-none mb-0"
        >
          <li v-for="(error, key) in validationContext.errors" :key="key">
            {{ error }}
          </li>
        </ul>
      </transition>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormTags,
  BFormGroup,
  BDropdown,
  BDropdownForm,
  BFormInput,
  BDropdownDivider,
  BDropdownItem,
  BFormTag,
  BDropdownText,
  BIcon,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@core/comp-functions/forms/form-validation";
// TODO : create dynamic import for rules

import {
 required, alphaNum, email, min, max
} from "@validations";

export default {
  components: {
    BFormTags,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BIcon,
    ValidationProvider,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      requried: false,
      default: "",
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    rules: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    search: "",
    value: [],
    searchQueryTimer: null, // used for debounce search
    errors: [],
  }),
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const { criteria } = this;

      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        // return options.filter(
        //   (opt) => opt.toLowerCase().indexOf(criteria) > -1
        // );
      }

      // Show all options available
      return options;
    },
    feedbackId() {
      if (!this.label) return "input-feedback";
      return `input${this.label.replace(/ /g, "-")}-feedback`;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    getValidationState,
    emitSearch(text) {
      // debounce
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        // if (!searchText) return;
        this.$emit("search", text);
      }, 300);
    },
    onOptionClick({ option, addTag }) {
      addTag(option.uuid);
      this.search = "";
    },
    tagsHandler(tags) {
      console.log(
        "🚀 ~ file: AppTags.vue ~ line 140 ~ tagsHandler ~ tags",
        tags
      );
      this.$emit("input", tags);
    },
  },
};
</script>
