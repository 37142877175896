<template>
  <AppForm
    :id="id"
    :title="title"
    :cancel-label="cancelLabel"
    :submit-label="actionLabel"
    :is-loading="isLoading"
    :overlay-opacity="1"
    :variant="variant"
    @submit.prevent="submitHandler"
    @hide="hideModal"
  >
    <p v-if="!hideMessage">
      {{ message }}
    </p>

    <slot />
  </AppForm>
</template>

<script>
import { AppForm } from "@/components/form/index";
import i18n from "@/libs/i18n/index";

export default {
  name: "Warning",
  components: {
    AppForm,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "warning-modal",
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: i18n.t("are_you_sure") || "Are you sure?",
    },
    actionLabel: {
      type: String,
      required: false,
      default: i18n.t("delete") || "Delete",
    },
    cancelLabel: {
      type: String,
      required: false,
      default: i18n.t("cancel") || "Cancel",
    },
    variant: {
      type: String,
      required: false,
      default: "danger",
    },
    title: {
      type: String,
      required: false,
      default: i18n.t("delete") || "Delete",
    },
    payload: {
      type: [Object, Array],
      required: false,
      default: () => {},
    },
    hideMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    // action: {
    //   type: Object,
    //   required: false,
    //   default: () => null,
    // },
  },
  computed: {
    // actionLabelToDisplay() {
    //   if (this.action) return this.action.label;
    //   return this.actionLabel;
    // },
  },
  methods: {
    submitHandler() {
      this.$emit("approve", this.id, this.payload);
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", this.id);
      this.$emit("cancel", this.id, this.payload);
    },
  },
  watch: {
    // action(val) {
    //   if(!val) return;
    //   this.actionLabel = val.label || i18n.t("delete") || "Delete";
    // },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
