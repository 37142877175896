import CRUD from '@/store/mixins/crud'
import axios from '@/libs/axios'

const crud = new CRUD('comity-members')

export default {
    namespaced: true,
    state: {
        ...crud.state,
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
    },
    actions: {
        ...crud.actions,
    },
}
