<template>
  <component
    :is="component"
    :id="id"
    :size="size"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :visible="visible"
    @show="showModal"
    @hide="hideModal"
  >
    <!-- Header -->
    <div
      v-if="!plain"
      class="
        d-flex
        justify-content-between
        align-items-center
        content-sidebar-header
        py-1
        mb-1
      "
    >
      <h5 class="mb-0">
        {{ title }}
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hideModal"
      />
    </div>

    <!-- Form -->
    <b-overlay :show="isLoading" :opacity="overlayOpacity">
      <b-form ref="form" v-bind="$attrs" v-on="$listeners">
        <ServerErrors
          v-if="errorsBag && Object.keys(errorsBag)"
          :errors-bag="errorsBag"
        />
        <slot />
        <!-- <transition name="slide"> -->
        <slot v-if="mode !== 'view' && !hideFooter" name="footer">
          <footer :class="footerClasses">
            <AppButton
              v-if="!removeCancelBtn"
              :variant="cancelVariant"
              class="mx-md-2 my-2 my-md-0"
              type="button"
              data-test="form-cancel-btn"
              :label="cancelLabel"
              @click="hideModal"
            />
            <AppButton
              v-if="displaySubmitAndAddNew"
              class="mr-md-2 my-2 my-md-0"
              :variant="variant"
              :disabled="doesErrorsExist"
              data-test="form-submit-and-add-new-btn"
              type="button"
              :label="submitAndAddNewLabel"
              @click="submitAndAddNew"
            />
            <AppButton
              :variant="variant"
              data-test="form-submit-btn"
              :disabled="doesErrorsExist"
              type="submit"
              :label="submitLabel"
            />
          </footer>
        </slot>
        <!-- </transition> -->
      </b-form>
    </b-overlay>
  </component>
</template>

<script>
import { BForm, BOverlay } from "bootstrap-vue";
import ServerErrors from "./ServerErrors.vue";
import AppButton from "./AppButton.vue";
import i18n from "@/libs/i18n/index";

export default {
  name: "AppForm",
  components: {
    BForm,
    BOverlay,
    ServerErrors,
    AppButton,
  },
  props: {
    errorsBag: {
      type: [Array, Object],
      default: null,
    },
    submitLabel: {
      type: String,
      default: i18n.t("submit") || "Submit",
    },
    cancelLabel: {
      type: String,
      default: i18n.t("cancel") || "Cancel",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "app-form",
    },
    mode: {
      type: String,
      default: "edit",
    },
    plain: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: null,
    },
    overlayOpacity: {
      type: [Number, String],
      default: 0.5,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: true,
    },
    removeCancelBtn: {
      type: Boolean,
      default: false,
    },
    footerClasses: {
      type: String,
      default: "d-flex mt-2 mb-1 justify-content-end",
    },
    successTitle: {
      type: String,
      default: i18n.t("success") || "Success",
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    cancelVariant: {
      type: String,
      default: "outline-secondary",
    },
    submitAndAddNewLabel: {
      type: String,
      default: i18n.t("submit_and_add_new") || "Submit and Add New",
    },
    displaySubmitAndAddNew: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      component: this.plain ? "div" : "AppModal",
      successMSG: this.successMessage || i18n.t("success_message"),
    };
  },
  computed: {
    doesErrorsExist() {
      return !!(
        // (this.errorsBag &&
        //   Object.keys(this.errorsBag) &&
        //   Object.keys(this.errorsBag).length) ||
        (this.errors && this.errors.items && this.errors.items.length)
      );
    },
  },
  watch: {
    // successMessage(newVal) {
    //   this.successMSG = newVal;
    // },
    successMessage(newVal) {
      if (!newVal) return;
      // generator function
      function* showSuccessMSG(context) {
        yield new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            context.$bvToast.toast(newVal, {
              title: context.successTitle,
              variant: "success",
              solid: true,
              appendToast: true,
              autoHideDelay: 5000,
              // toaster: "b-toaster-bottom-right",
            });
          }, 1000);
        });
      }

      const gen = showSuccessMSG(this);
      if (newVal) {
        gen.next(this);
      }

      setTimeout(() => {
        this.$emit("hideSuccessMessage");
      }, 1000);
    },
  },
  methods: {
    // t,
    hideModal() {
      this.$emit("hide");
      this.successMSG = null;
      this.$bvModal.hide(this.id);
    },
    showModal() {
      this.$emit("show");
      this.$bvModal.show(this.id);
    },
    submitAndAddNew() {
      this.$emit("submitAndAddNew");
    },
  },
};
</script>
