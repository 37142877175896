import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import GlobalComponents from '@/components/global/index'
import userCan from '@/mixins/UserCan'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.prototype.$userCan = userCan

GlobalComponents.forEach(component => {
    Vue.component(component.name, component)
})
