import axios from '@/libs/axios'

export default class {
    constructor(name) {
        this.state = {
            name,
            data: [],
            singleData: null,
            meta: {},
            isLoading: {
                fetching: false,
                creating: false,
                updating: false,
                deleting: false,
            },
            errors: null,
            filters: null
        };
        this.mutations = {
            SET_DATA(state, data) {
                state.data = data
            },
            SET_META(state, data) {
                state.meta = data
            },
            SET_LOADING(state, obj) {
                Object.keys(obj).forEach(key => {
                    state.isLoading[key] = obj[key]
                })
            },
            SET_ERRORS(state, errors) {
                state.errors = errors
            },
            RESET_ERRORS(state) {
                state.errors = {}
            },
            SET_SINGLE_DATA(state, data) {
                state.singleData = data
            },
            SET_FILTERS(state, filters) {
                state.filters = filters
            }
        };
        this.getters = {};
        this.actions = {
            resetModule({ commit }) {
                commit('RESET_ERRORS')
                commit('SET_SINGLE_DATA', null)
                commit('SET_FILTERS', null)
            },
            resetFilters({ commit }) {
                commit('SET_FILTERS', null)
            },
            fetchAllData({ commit }, params) {
                commit('SET_LOADING', { fetching: true })
                const paramsString = params ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : ''

                return new Promise((resolve, reject) => {
                    axios.get(`/${name}/listing-all?${paramsString}`)
                        .then(response => {
                            commit('SET_DATA', response.data.data)
                            if (response.data.meta) commit('SET_META', response.data.meta)
                            commit('SET_LOADING', { fetching: false })
                            commit('SET_ERRORS', null)
                            resolve(response.data)
                        })
                        .catch(error => {
                            commit('SET_LOADING', { fetching: false })
                            if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                            reject(error)
                        })
                })
            },
            fetchSingleItem({ commit, dispatch }, uuid) {
                commit('SET_LOADING', { fetching: true })
                dispatch('resetModule')
                return new Promise((resolve, reject) => {
                    axios.post(`/${name}/details`, { uuid })
                        .then(response => {
                            commit('SET_SINGLE_DATA', response.data.data)
                            if (response.data.meta) commit('SET_META', response.data.meta)
                            commit('SET_LOADING', { fetching: false })
                            commit('SET_ERRORS', null)
                            resolve(response.data)
                        })
                        .catch(error => {
                            commit('SET_LOADING', { fetching: false })
                            if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                            reject(error)
                        })
                })
            },
            fetchData({ commit }, params) {
                commit('SET_LOADING', { fetching: true })

                commit('SET_FILTERS', params)
                const paramsString = params ? Object.keys(params).map(key => (typeof params[key] === 'object' && !params[key]?.['value'] ? '' : `${key}=${params[key]}`)).filter(Boolean).join('&') : ''

                return new Promise((resolve, reject) => {
                    axios.get(`/${name}/listing?${paramsString}`)
                        .then(response => {
                            commit('SET_DATA', response.data.data)
                            if (response.data.meta) commit('SET_META', response.data.meta)
                            commit('SET_LOADING', { fetching: false })
                            commit('SET_ERRORS', null)
                            resolve(response.data)
                        })
                        .catch(error => {
                            commit('SET_LOADING', { fetching: false })
                            if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                            reject(error)
                        })
                })
            },
            createData({ commit, dispatch }, payload) {
                commit('SET_LOADING', { creating: true })
                return new Promise((resolve, reject) => {
                    axios.post(`/${name}/add`, payload)
                        .then(response => {
                            dispatch('fetchData')
                            commit('SET_LOADING', { creating: false })
                            commit('SET_ERRORS', null)
                            resolve(response.data)
                        })
                        .catch(error => {
                            commit('SET_LOADING', { creating: false })
                            if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                            reject(error)
                        })
                })
            },
            updateData({ commit, dispatch }, payload) {
                commit('SET_LOADING', { updating: true })
                return new Promise((resolve, reject) => {
                    axios.post(`/${name}/edit`, payload)
                        .then(response => {
                            dispatch('fetchData')
                            commit('SET_LOADING', { updating: false })
                            commit('SET_ERRORS', null)
                            resolve(response.data)
                        })
                        .catch(error => {
                            commit('SET_LOADING', { updating: false })
                            if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                            reject(error)
                        })
                })
            },
            deleteData({ commit, dispatch }, payload) {
                commit('SET_LOADING', { deleting: true })
                return new Promise((resolve, reject) => {
                    axios.post(`/${name}/delete`, payload)
                        .then(response => {
                            dispatch('fetchData')
                            commit('SET_LOADING', { deleting: false })
                            commit('SET_ERRORS', null)
                            resolve(response.data)
                        })
                        .catch(error => {
                            commit('SET_LOADING', { deleting: false })
                            if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                            reject(error)
                        })
                })
            }
        };
    }
}
