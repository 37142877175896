<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="rules"
  >
    <b-form-group
      v-slot="{ ariaDescribedby }"
      :label="label"
      :class="groupClass"
      :label-class="labelClass"
    >
      <b-form-checkbox-group
        :id="id"
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        :name="id"
        :state="getValidationState(validationContext)"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <transition name="slide">
        <ul
          v-if="validationContext.errors && validationContext.errors.length"
          class="text-danger p-0 list-style-none mb-0"
        >
          <li v-for="(error, key) in validationContext.errors" :key="key">
            {{ error }}
          </li>
        </ul>
      </transition>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormCheckboxGroup } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { getValidationState } from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "app-checkboxes",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    groupClass: {
      type: String,
      default: "",
    },
    initialValue: {
      type: [Array, Number],
      default: () => [],
    },
    rules: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  created() {
    this.selected = this.initialValue;
  },
  methods: {
    getValidationState,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control {
  margin-block: 0.5rem 1rem;
}
</style>
