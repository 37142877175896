import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        data: null,
        isLoading: false
    },
    mutations: {
        SET_DATA(state, data) {
            state.data = data
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading
        }
    },
    actions: {
        fetchStats({ commit }, params) {
            commit('SET_LOADING', true)
            const paramsString = params ? Object.keys(params).map(key => `${key}=${params[key]}`).join('&') : ''

            return new Promise((resolve, reject) => {
                axios.get(`/dashboard?${paramsString}`)
                    .then(response => {
                        commit('SET_DATA', response.data.data)
                        commit('SET_LOADING', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('SET_LOADING', false)
                        reject(error)
                    })
            })
        }
    },
}
