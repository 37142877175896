<template>
  <div>
    <b-form-group class="mt-2">
      <template v-if="label" #label :for="generateID ? generateID : ''">
        <span class="label">{{ label }}</span>
      </template>
      <b-form-textarea
        :id="generateID"
        :placeholder="placeholder"
        lazy-formatter
        :formatter="formatter"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormTextarea } from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    generateID() {
      return `radio-btn-${this.options?.length}`;
    },
  },
  methods: {
    formatter(value) {
      return value.toLowerCase();
    },
  },
};
</script>
