import CRUD from '@/store/mixins/crud'
// import axios from '@/libs/axios'

const crud = new CRUD('image')

export default {
    namespaced: true,
    state: {
        ...crud.state,
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
    },
    actions: {
        ...crud.actions,
        // deleteAllSelectedRows({ commit, dispatch, state }, payload) {
        //     // documents / multi - delete
        //     return new Promise((resolve, reject) => {
        //         // commit('DELETE_ALL_SELECTED_ROWS', payload)
        //         axios.post(`/documents/multi-delete`, payload)
        //             .then(response => {
        //                 dispatch('fetchData')
        //                 commit('SET_LOADING', { deleting: false })
        //                 commit('SET_ERRORS', null)
        //                 resolve(response.data)
        //             })
        //             .catch(error => {
        //                 commit('SET_LOADING', { deleting: false })
        //                 if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
        //                 reject(error)
        //             })

        //         resolve()
        //     })
        // }
    },
}
