<template>
  <div v-if="errorsBag && errorsBag.status">
    <b-alert variant="danger" show>
      <div
        v-if="errorsBag.status == 400 && errorsBag.errors"
        class="alert-body"
      >
        <span v-if="typeof errorsBag.errors === 'string'">
          {{ errorsBag.errors }}
        </span>
        <span v-else>
          <span v-for="(err, index) in errorsBag.errors" :key="index">
            <ul v-if="typeof err === 'string'">
              {{
                err
              }}
            </ul>
            <span v-else>
              <ul v-for="(item, i) in err" :key="i">
                <li class="mb-1" @click="goToError(item)">{{ item }}</li>
              </ul>
            </span>
          </span>
        </span>
      </div>
      <div v-else class="alert-body">
        something went wrong
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";

export default {
  name: "ServerErrors",
  components: {
    BAlert,
  },
  props: {
    errorsBag: {
      type: [Array, Object],
      required: true,
    },
    //
  },
  methods: {
    goToError(error) {
      this.$emit("goToError", error);
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.alert {
  border-left: 4px solid red;
}
ul {
  padding-block-start: 1rem;
  margin: 0;
}
ul,
li {
  list-style: none;
}
</style>
