

















import { BSpinner, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "@/@types/vue-ripple-directive";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: { BSpinner, BButton },
  directives: {
    Ripple,
  },
})
export default class AppButton extends Vue {
  @Prop(String) readonly label: string | null;

  @Prop(String) readonly align: string | null;

  @Prop(String) readonly icon: string | null;

  @Prop({ type: Boolean, default: true }) readonly clickable: boolean;

  @Prop({ type: Boolean, default: false }) readonly invalid: boolean;

  @Prop({ type: Boolean, default: false }) readonly disabled: boolean;

  @Prop({ type: Boolean, default: false }) readonly loading: boolean;
}
