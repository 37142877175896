<template>
  <div>
    <b-modal
      :id="id"
      :modal-class="['custom-modal', id]"
      centered
      hide-header
      hide-footer
      v-bind="$attrs"
      v-on="$listeners"
      @show="activateModalHandler(id)"
      @hide="removeHashFromUrl(id)"
    >
      <slot v-if="activeModal === id" />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "AppModal",
  components: { BModal },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    id: {
      type: String,
      required: false,
      default: "custom-modal",
    },
  },
  data() {
    return {
      activeModal: null,
    };
  },
  mounted() {
    window.onhashchange = (e) => {
      this.$root.$emit("bv::show::modal", location.hash.replace(/#/g, ""));
    };
  },
  methods: {
    activateModalHandler(id) {
      this.activeModal = id;
      this.addHashToUrl(id);
    },
    closeModal(id) {
      this.$root.$emit("bv::hide::modal", location.hash.replace(/#/g, ""));
    },
    addHashToUrl(id) {
      if (location.hash !== `#${id}`) {
        history.pushState({}, "", `#${id}`);
      }
    },
    removeHashFromUrl(id) {
      window.history.replaceState(
        "",
        document.title,
        window.location.href.replace(/#.*$/, "")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep button,
::v-deep a.btn {
  border-radius: 100px;
}

::v-deep .modal .modal-dialog {
  margin-top: 50px;
  min-width: 556px;

  @media (max-width: 767px) {
    margin-top: 50px;
    min-width: auto;
  }
}

// ::v-deep .close_modal {
//   height: 50px;
//   position: absolute;
//   top: -50px;
//   text-align: center;
// }
</style>
