import i18n from '@/libs/i18n/index'

export default [
    {
        path: '/actions',
        name: 'actions',
        component: () => import('@/views/actions/index.vue'),
        meta: {
            pageTitle: i18n.t('Actions') || 'Actions',
            permission: 'actions-access',
            breadcrumb: [
                {
                    text: i18n.t('Actions') || 'Actions',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/permissions',
        name: 'permissions',
        component: () => import('@/views/permissions/index.vue'),
        meta: {
            pageTitle: i18n.t('Permissions') || 'Permissions',
            permission: 'permissions-access',
            breadcrumb: [
                {
                    text: i18n.t('Permissions') || 'Permissions',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/roles/index.vue'),
        meta: {
            pageTitle: i18n.t('roles') || 'Roles',
            permission: 'roles-access',
            breadcrumb: [
                {
                    text: i18n.t('roles') || 'Roles',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/users/index.vue'),
        meta: {
            pageTitle: i18n.t('users') || 'Users',
            permission: 'users-access',
            breadcrumb: [
                {
                    text: i18n.t('users') || 'Users',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/user/:uuid',
        name: 'user',
        component: () => import('@/views/users/singleUser.vue'),
        meta: {
            pageTitle: 'User',
            permission: 'users-access',
            breadcrumb: [
                {
                    text: i18n.t('users') || 'Users',
                    to: '/users',
                },
                {
                    text: i18n.t('users') || 'User',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/actors',
        name: 'actors',
        component: () => import('@/views/actors/index.vue'),
        meta: {
            pageTitle: i18n.t('actors') || 'Actors',
            permission: 'actors-access',
            breadcrumb: [
                {
                    text: i18n.t('actors') || 'Actors',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/gallery',
        name: 'gallery',
        component: () => import('@/views/gallery/index.vue'),
        meta: {
            pageTitle: i18n.t('gallery') || 'gallery',
            // permission: 'gallery-access',
            breadcrumb: [
                {
                    text: i18n.t('gallery') || 'gallery',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news-category',
        name: 'news-category',
        component: () => import('@/views/news-module/news-category/index.vue'),
        meta: {
            pageTitle: i18n.t('news_category') || 'news Category',
            permission: 'news_category-access',
            breadcrumb: [
                {
                    text: i18n.t('news') || 'news',
                    to: '/news',
                },
                {
                    text: i18n.t('news_category') || 'news Category',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news-tag',
        name: 'news-tag',
        component: () => import('@/views/news-module/news-tag/index.vue'),
        meta: {
            pageTitle: i18n.t('news_tags') || 'news Tags',
            permission: 'news_tag-access',
            breadcrumb: [
                {
                    text: i18n.t('news') || 'news',
                    to: '/news',
                },
                {
                    text: i18n.t('news_tags') || 'Articles Tags',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news-module/news/index.vue'),
        meta: {
            pageTitle: i18n.t('News') || 'News',
            permission: 'news-access',
            breadcrumb: [
                {
                    text: i18n.t('News') || 'News',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/add-new-news',
        name: 'add-new-news',
        component: () => import('@/views/news-module/news/add-new-news.vue'),
        meta: {
            pageTitle: i18n.t('add_new_news') || 'Add New news',
            permission: 'news-add',
            breadcrumb: [
                {
                    text: i18n.t('news') || 'News',
                    to: '/news',
                },
                {
                    text: i18n.t('add_new_news') || 'Add New news',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/edit-news',
        name: 'edit-news',
        component: () => import('@/views/news-module/news/add-new-news.vue'),
        meta: {
            pageTitle: i18n.t('edit_news') || 'Edit news',
            permission: 'news-edit',
            breadcrumb: [
                {
                    text: i18n.t('news') || 'News',
                    to: '/news',
                },
                {
                    text: i18n.t('edit_news') || 'Edit news',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages',
        name: 'pages',
        component: () => import('@/views/pages-module/index.vue'),
        meta: {
            pageTitle: i18n.t('pages') || 'Pages',
            permission: 'post-access',
            breadcrumb: [
                {
                    text: i18n.t('pages') || 'Pages',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/add-new-page',
        name: 'add-new-page',
        component: () => import('@/views/pages-module/add-new-page.vue'),
        meta: {
            pageTitle: i18n.t('add_new_page') || 'Add New Page',
            permission: 'post-add',
            breadcrumb: [
                {
                    text: i18n.t('Pages') || 'Pages',
                    to: '/pages',
                },
                {
                    text: i18n.t('add_new_page') || 'Add New Page',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/edit-page',
        name: 'edit-page',
        component: () => import('@/views/pages-module/add-new-page.vue'),
        meta: {
            pageTitle: i18n.t('edit_page') || 'Edit Page',
            permission: 'post-edit',
            breadcrumb: [
                {
                    text: i18n.t('Pages') || 'Pages',
                    to: '/pages',
                },
                {
                    text: i18n.t('edit_page') || 'Edit Page',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/account-settings',
        name: 'account-settings',
        component: () => import('@/views/account-settings/index.vue'),
        meta: {
            pageTitle: i18n.t('Account Settings') || 'Account Settings',
            breadcrumb: [
                {
                    text: i18n.t('Account Settings') || 'Account Settings',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/countries',
        name: 'countries',
        component: () => import('@/views/membership/countries/index.vue'),
        meta: {
            pageTitle: i18n.t('Countries') || 'Countries',
            permission: 'countries-access',
            breadcrumb: [
                {
                    text: i18n.t('Countries') || 'Countries',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/governorates',
        name: 'governorates',
        component: () => import('@/views/membership/governorates/index.vue'),
        meta: {
            pageTitle: i18n.t('Governorates') || 'Governorates',
            permission: 'governorates-access',
            breadcrumb: [
                {
                    text: i18n.t('Governorates') || 'Governorates',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/areas',
        name: 'areas',
        component: () => import('@/views/membership/areas/index.vue'),
        meta: {
            pageTitle: i18n.t('Areas') || 'Areas',
            permission: 'areas-access',
            breadcrumb: [
                {
                    text: i18n.t('Areas') || 'Areas',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/slider',
        name: 'slider',
        component: () => import('@/views/slider/index.vue'),
        meta: {
            pageTitle: i18n.t('slider') || 'slider',
            permission: 'sliders-access',
            breadcrumb: [
                {
                    text: i18n.t('slider') || 'slider',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/videos',
        name: 'videos',
        component: () => import('@/views/videos/index.vue'),
        meta: {
            pageTitle: i18n.t('videos') || 'videos',
            permission: 'videos-access',
            breadcrumb: [
                {
                    text: i18n.t('videos') || 'videos',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/awards',
        name: 'awards',
        component: () => import('@/views/awards/index.vue'),
        meta: {
            pageTitle: i18n.t('awards') || 'awards',
            permission: 'awards-access',
            breadcrumb: [
                {
                    text: i18n.t('awards') || 'awards',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/add-new-award',
        name: 'add-new-award',
        component: () => import('@/views/awards/add-new-award.vue'),
        meta: {
            pageTitle: i18n.t('add_new_award') || 'Add New award',
            permission: 'awards-add',
            breadcrumb: [
                {
                    text: i18n.t('awards') || 'awards',
                    to: '/awards',
                },
                {
                    text: i18n.t('add_new_award') || 'Add New award',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/edit-award',
        name: 'edit-award',
        component: () => import('@/views/awards/add-new-award.vue'),
        meta: {
            pageTitle: i18n.t('edit_award') || 'Edit award',
            permission: 'awards-edit',
            breadcrumb: [
                {
                    text: i18n.t('awards') || 'awards',
                    to: '/awards',
                },
                {
                    text: i18n.t('edit_award') || 'Edit award',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/applications',
        name: 'applications',
        component: () => import('@/views/applications/index.vue'),
        meta: {
            pageTitle: i18n.t('applications') || 'applications',
            permission: ['application_individual-access', 'application_team-access'],
            breadcrumb: [
                {
                    text: i18n.t('applications') || 'applications',
                    active: true,
                },
            ],
        },
    },
    // application id page 
    {
        path: '/application/:id',
        name: 'application',
        component: () => import('@/views/applications/singleApplicationPage.vue'),
        meta: {
            pageTitle: i18n.t('application') || 'application',
            permission: ['application_individual-access', 'application_team-access'],
            breadcrumb: [
                {
                    text: i18n.t('applications') || 'applications',
                    to: '/applications',
                },
                {
                    text: i18n.t('application') || 'application',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/views/contact-us/index.vue'),
        meta: {
            pageTitle: i18n.t('contact_us') || 'contact-us',
            permission: 'contact_us-access',
            breadcrumb: [
                {
                    text: i18n.t('contact_us') || 'contact-us',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/settings/index.vue'),
        meta: {
            pageTitle: i18n.t('Settings') || 'Settings',
            permission: 'general_settings-access',
            breadcrumb: [
                {
                    text: i18n.t('Settings') || 'Settings',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/comity',
        name: 'comity',
        component: () => import('@/views/comity/index.vue'),
        meta: {
            pageTitle: i18n.t('comity') || 'Comity',
            permission: 'comity-access',
            breadcrumb: [
                {
                    text: i18n.t('comity') || 'Comity',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/kpi',
        name: 'kpi',
        component: () => import('@/views/kpi/index.vue'),
        meta: {
            pageTitle: i18n.t('kpi') || 'Kpi',
            permission: 'kpi-access',
            breadcrumb: [
                {
                    text: i18n.t('kpi') || 'Kpi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/judges',
        name: 'judges',
        component: () => import('@/views/comity/judges/index.vue'),
        meta: {
            pageTitle: i18n.t('judges') || 'Judges',
            permission: 'judges-access',
            breadcrumb: [
                {
                    text: i18n.t('judges') || 'Judges',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/add-new-judge',
        name: 'add-new-judge',
        component: () => import('@/views/comity/judges/add-new-judge.vue'),
        meta: {
            pageTitle: i18n.t('add_new_judge') || 'Add New Judge',
            permission: 'judges-add',
            breadcrumb: [
                {
                    text: i18n.t('judges') || 'judges',
                    to: '/judges',
                },
                {
                    text: i18n.t('add_new_judge') || 'Add New Judge',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/edit-judge',
        name: 'edit-judge',
        component: () => import('@/views/comity/judges/add-new-judge.vue'),
        meta: {
            pageTitle: i18n.t('edit_judge') || 'Edit judge',
            permission: 'judges-edit',
            breadcrumb: [
                {
                    text: i18n.t('judges') || 'judges',
                    to: '/judges',
                },
                {
                    text: i18n.t('edit_judges') || 'Edit judges',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/rejection-reasons',
        name: 'rejection-reasons',
        component: () => import('@/views/rejection-reasons/index.vue'),
        meta: {
            pageTitle: i18n.t('rejection_reasons') || 'rejection reasons',
            permission: 'rejection_reasons-access',
            breadcrumb: [
                {
                    text: i18n.t('rejection_reasons') || 'rejection reasons',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
].filter(Boolean)
