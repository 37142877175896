import CRUD from '@/store/mixins/crud'
import axios from '@/libs/axios'

const crud = new CRUD('contact-us')

export default {
    namespaced: true,
    state: {
        ...crud.state,
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
    },
    actions: {
        ...crud.actions,
        turnToReadHandler({ commit, dispatch }, payload) {
            commit('SET_LOADING', { updating: true })
            return new Promise((resolve, reject) => {
                axios.post(`/contact-us/read-toggle`, payload)
                    .then(response => {
                        dispatch('fetchData')
                        commit('SET_LOADING', { updating: false })
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { updating: false })
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
    },
}
