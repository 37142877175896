import axios from '@axios'
import CRUD from '@/store/mixins/crud'

const crud = new CRUD('roles')

export default {
  namespaced: true,
  state: {
    ...crud.state
  },
  getters: {
    ...crud.getters
  },
  mutations: {
    ...crud.mutations,
  },
  actions: {
    ...crud.actions,
  },
}

// export default {
//   namespaced: true,
//   state: {
//     roles: [],
//     isLoading: {
//       fetching: false,
//       creating: false,
//       updating: false,
//       deleting: false,
//     },
//     errors: {},
//   },
//   getters: {},
//   mutations: {
//     setRoles(state, payload) {
//       state.roles = payload
//     },
//     setLoading(state, obj) {
//       Object.keys(obj).forEach(key => {
//         state.isLoading[key] = obj[key]
//       })
//     },
//     setErrors(state, errors) {
//       state.errors = errors
//     },
//   },
//   actions: {
//     fetchRoles({ commit }, payload) {
//       commit('setLoading', { fetching: true })
//       return new Promise((resolve, reject) => {
//         axios.get('/roles/listing')
//           .then(response => {
//             commit('setRoles', response.data.data)
//             commit('setLoading', { fetching: false })
//             commit('setErrors', {})
//             resolve(response.data)
//           })
//           .catch(error => {
//             commit('setLoading', { fetching: false })
//             commit('setErrors', error.response.data.errors)
//             reject(error)
//           })
//       })
//     },
//     createRole({ commit, dispatch }, payload) {
//       commit('setLoading', { creating: true })
//       return new Promise((resolve, reject) => {
//         axios.post('/roles/add', payload)
//           .then(response => {
//             dispatch('fetchRoles')
//             commit('setLoading', { creating: false })
//             commit('setErrors', {})
//             resolve(response.data)
//           })
//           .catch(error => {
//             commit('setLoading', { creating: false })
//             commit('setErrors', error.response.data.errors)
//             reject(error)
//           })
//       })
//     },
//     updateRole({ commit, dispatch }, payload) {
//       commit('setLoading', { updating: true })
//       return new Promise((resolve, reject) => {
//         axios.post('/roles/edit', payload)
//           .then(response => {
//             dispatch('fetchRoles')
//             commit('setLoading', { updating: false })
//             commit('setErrors', {})
//             resolve(response.data)
//           })
//           .catch(error => {
//             commit('setLoading', { updating: false })
//             commit('setErrors', error.response.data.errors)
//             reject(error)
//           })
//       })
//     },
//     deleteRole({ commit, dispatch }, payload) {
//       commit('setLoading', { deleting: true })
//       return new Promise((resolve, reject) => {
//         axios.post('/roles/delete', payload)
//           .then(response => {
//             dispatch('fetchRoles')
//             commit('setLoading', { deleting: false })
//             commit('setErrors', {})
//             resolve(response.data)
//           })
//           .catch(error => {
//             commit('setLoading', { updating: false })
//             commit('setErrors', error.response.data.errors)
//             reject(error)
//           })
//       })
//     },
//   },
// }
