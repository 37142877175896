import CRUD from '@/store/mixins/crud'
import axios from '@/libs/axios'

const crud = new CRUD('application-team')

export default {
    namespaced: true,
    state: {
        ...crud.state,
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
    },
    actions: {
        ...crud.actions,
        approveHandler({ commit, dispatch, state }, payload) {
            commit('SET_LOADING', { updating: true })
            return new Promise((resolve, reject) => {
                axios.post(`/application-team/approve`, payload)
                    .then(response => {
                        dispatch('fetchData', state.filters)
                        commit('SET_LOADING', { creating: false })
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                        window.location.reload();
                    })
                    .catch(error => {
                        commit('SET_LOADING', { creating: false })
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
        incompleteHandler({ commit, dispatch, state }, payload) {
            commit('SET_LOADING', { updating: true })
            return new Promise((resolve, reject) => {
                axios.post(`/application-team/incomplete-application`, payload)
                    .then(response => {
                        dispatch('fetchData', state.filters)
                        commit('SET_LOADING', { creating: false })
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                        window.location.reload();
                    })
                    .catch(error => {
                        commit('SET_LOADING', { creating: false })
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
        rejectHandler({ commit, dispatch, state }, payload) {
            commit('SET_LOADING', { updating: true })
            return new Promise((resolve, reject) => {
                axios.post(`/application-team/rejected`, payload)
                    .then(response => {
                        dispatch('fetchData', state.filters)
                        commit('SET_LOADING', { creating: false })
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                        window.location.reload();
                    })
                    .catch(error => {
                        commit('SET_LOADING', { creating: false })
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
    },
}
