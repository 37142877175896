import Vue from 'vue'

// axios
import axiosInit from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

const axios = axiosInit.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    'Access-Control-Allow-Origin': '*',
    lang: window.localStorage.getItem('language') || 'ar',
  }
})

function errorResponseHandler(error) {
  // check for errorHandle config
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error);
  }

  // if has response show the error
  if (error.response) {
    if (error.response.data.status == 403) {
      // remove token from localStorage
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userData')

      // redirect to login page
      // TODO : use redirectURL in after  login
      router.push({ name: 'auth-login', params: { message: 'You are not authanticated', redirectURL: router.currentRoute.fullPath } })
    }

    if (error.response.data.status != 400 && error.response.data.status != 403) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          icon: 'AlertCircleIcon',
          title: error.response.data && error.response.data.status,
          text: error.response.data && error.response.data.message,
          variant: 'danger',
        },
      }, { timeout: 5000 })
      throw error.response.data;
    } else {
      return Promise.reject(error);
    }
  }
}

function responseHandler(response) {
  // if (response.config.method === 'post' && response.data.status == 200) {
  //   Vue.$toast({
  //     component: ToastificationContent,
  //     props: {
  //       title: 'Notification',
  //       icon: 'BellIcon',
  //       text: response.data.message,
  //       variant: 'success',
  //     },
  //   })
  // }
  return response;
}

// apply interceptor on response
axios.interceptors.response.use(
  responseHandler,
  errorResponseHandler
);

Vue.prototype.$http = axios
export default axios
