<template>
  <b-form-group>
    <template v-if="label" #label :for="id">
      <span class="label">{{ label }}</span>
    </template>
    <div class="d-flex">
      <flat-pickr
        key="value"
        v-model="value"
        class="form-control"
        :dir="isRTL ? 'rtl' : 'ltr'"
        :placeholder="$t('no_date_selected') || 'No date Selected'"
        :config="{
          mode,
          locale,
          maxDate,
          minDate,
          shorthand,
          dateFormat,
          altFormat,
        }"
        v-bind="$attrs"
        v-on="$listeners"
        @on-change="valueHandler"
      />
      <div v-if="value && clearable" key="clear_button">
        <AppButton class="ml-2" variant="danger" @click="clearValue">
          {{ $t("clear") || "Clear" }}
        </AppButton>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Arabic } from "flatpickr/dist/l10n/ar";
import { AppButton } from "@/components/form/index";

export default {
  components: {
    flatPickr,
    AppButton,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    mode: {
      type: String,
      required: false,
      default: "range",
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxDate: {
      type: [String, Date],
      required: false,
      default: null,
    },
    minDate: {
      type: [String, Date],
      required: false,
      default: null,
    },
    initialValue: {
      type: String,
      required: false,
      default: null,
    },
    shorthand: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateFormat: {
      type: String,
      required: false,
      default: "Y-m-d",
    },
    altFormat: {
      type: String,
      required: false,
      default: "d-m-Y",
    },
  },
  data() {
    return {
      value: null,
      valueFormatted: null,
    };
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    locale() {
      return this.isRTL ? Arabic : "en";
    },
  },
  watch: {
    initialValue: {
      handler(val, oldVal) {
        if (!val) return;
        if (val === oldVal) return;
        this.value = val;
      },
      immediate: true,
    },
  },
  methods: {
    clearValue() {
      this.value = null;
      this.valueFormatted = null;
      this.$emit("clear");
    },
    valueHandler(_val) {
      if (!_val) return;
      this.$emit(
        "change",
        _val.map((el) =>
          new Date(el)
            .toLocaleDateString()
            .split("/")
            .join("-")
            .replace(/ /g, "")
        )
      );
      this.$emit(
        "changeDate",
        _val.map((el) =>
          new Date(el)
            .toLocaleDateString()
            .split("/")
            .join("-")
            .replace(/ /g, "")
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
[dir] .form-control:disabled {
  background-color: #efefef;
}
</style>
