import axios from '@axios'
import CRUD from '@/store/mixins/crud'

const crud = new CRUD('users')

export default {
    namespaced: true,
    state: {
        ...crud.state,
        userData: null,
        fetchingUserData: false,
    },
    getters: {
        ...crud.getters
    },
    mutations: {
        ...crud.mutations,
        SET_SINGLE(state, userData) {
            state.userData = userData
        },
        SET_FETCHING_USER(state, isloading) {
            state.isloading = isloading
        }
    },
    actions: {
        ...crud.actions,
        fetchUserSettings({ commit }) {
            commit('SET_LOADING', { fetching: true })
            return new Promise((resolve, reject) => {
                axios.get('/users/profile')
                    .then(response => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', response.data.data)
                        commit('SET_ERRORS', {})
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', null)
                        commit('SET_ERRORS', error.response.data.errors)
                        reject(error)
                    })
            })
        },
        updateUserSettings({ commit }, data) {
            commit('SET_LOADING', { fetching: true })
            return new Promise((resolve, reject) => {
                axios.post('/users/profile/general-settings', data)
                    .then(response => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', response.data.data)
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', null)
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
        updateUserPassword({ commit }, data) {
            commit('SET_LOADING', { fetching: true })
            return new Promise((resolve, reject) => {
                axios.post('/users/profile/update-password', data)
                    .then(response => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', response.data.data)
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', null)
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },
        fetchUserData({ commit }, { uuid = null }) {
            commit('SET_LOADING', { fetching: true })
            return new Promise((resolve, reject) => {
                axios.post('/users/data', { uuid })
                    .then(response => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', response.data.data)
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { fetching: false })
                        commit('SET_SINGLE', null)
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)

                        reject(error)
                    })
            })
        },
        toggleActivation({ commit, dispatch }, payload) {
            commit('SET_LOADING', { deleting: true })
            return new Promise((resolve, reject) => {
                axios.post('/users/activation-toggle', payload)
                    .then(response => {
                        dispatch('fetchData')
                        commit('SET_LOADING', { deleting: false })
                        commit('SET_ERRORS', null)
                        resolve(response.data)
                    })
                    .catch(error => {
                        commit('SET_LOADING', { deleting: false })
                        if (error.response && error.response.data) commit('SET_ERRORS', error.response.data)
                        reject(error)
                    })
            })
        },

    },
}
