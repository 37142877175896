var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))])]},proxy:true}:null],null,true)},[_c('div',{staticClass:"d-flex"},[_c('flat-pickr',_vm._g(_vm._b({key:"value",staticClass:"form-control",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.$t('no_date_selected') || 'No date Selected',"config":{
        mode: _vm.mode,
        locale: _vm.locale,
        maxDate: _vm.maxDate,
        minDate: _vm.minDate,
        shorthand: _vm.shorthand,
        dateFormat: _vm.dateFormat,
        altFormat: _vm.altFormat,
      }},on:{"on-change":_vm.valueHandler},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'flat-pickr',_vm.$attrs,false),_vm.$listeners)),(_vm.value && _vm.clearable)?_c('div',{key:"clear_button"},[_c('AppButton',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":_vm.clearValue}},[_vm._v(" "+_vm._s(_vm.$t("clear") || "Clear")+" ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }